/* SourceSantPro fonts */
@font-face {
  font-family: "SourceSansPro-Black";
  src: url("./Assets/Fonts/SourceSansPro/SourceSansPro-Black.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceSansPro-Bold";
  src: url("./Assets/Fonts/SourceSansPro/SourceSansPro-Bold.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceSansPro-ExtraLight";
  src: url("./Assets/Fonts/SourceSansPro/SourceSansPro-ExtraLight.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceSansPro-Light";
  src: url("./Assets/Fonts/SourceSansPro/SourceSansPro-Light.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceSansPro-Regular";
  src: url("./Assets/Fonts/SourceSansPro/SourceSansPro-Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceSansPro-Semibold";
  src: url("./Assets/Fonts/SourceSansPro/SourceSansPro-Semibold.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Georgia font */

@font-face {
  font-family: "Georgia-Regular";
  src: url("./Assets/Fonts/Georgia/Georgia-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Roboto font */

@font-face {
  font-family: "Roboto-Regular";
  src: url("./Assets/Fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("./Assets/Fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

#root {
  height: 100%;
  margin: auto;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

html,
body {
  height: 100%;
  margin: auto;
  font-family: "SourceSansPro-Regular", "Roboto-Regular", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}