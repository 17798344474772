/* Excelsior custom styles */
html {
  --antd-wave-shadow-color: #decaf2;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #decaf2;
  border-right-width: 0.1rem !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgb(84, 0, 168, 0.4);
  box-shadow: 0 0 0 0.2rem rgb(84, 0, 168, 0.4);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-family: "SourceSansPro-Semibold";
  background-color: #eee7f5;
  color: rgb(84, 0, 168, 1);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: rgb(84, 0, 168, 1);
}

::selection {
  color: #fff;
  background: #decaf2;
}

.ant-col,
.ant-form-item-label {
  width: 100%;
  text-align: left;
}

.ant-form,
.ant-form-horizontal {
  width: 100%;
}

.ant-row,
.ant-form-item,
.ant-form-item-has-success {
  width: 100%;
}

.ant-form-item {
  margin: 2rem 0rem;
}

.ant-form-item-label > label {
  height: auto;
  margin-bottom: 0;
}

.ant-input,
.ant-input-number,
.ant-select,
.ant-select-single,
.ant-select-show-arrow,
.ant-input-affix-wrapper {
  border: 0.1rem solid #decaf2;
  border-radius: 0.3rem;
}

.ant-input-affix-wrapper:hover,
.ant-input:hover,
.ant-input-number:hover,
.ant-select:hover,
.ant-select-single:hover,
.ant-select-show-arrow:hover {
  border-color: rgb(84, 0, 168, 0.6);
  border-radius: 0.3rem;
}

.ant-select
  .ant-select-single
  .ant-select-show-arrow
  .ant-select-open
  .ant-select-show-search {
  box-shadow: 0.3rem 0.3rem 0.6rem 0rem rgba(0, 0, 0, 0.12);
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  font-family: "SourceSansPro-Semibold";
  background-color: #decaf2;
  color: rgb(84, 0, 168, 1);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100%;
}

.ant-input-suffix {
  line-height: 100%;
}

.ant-input-affix-wrapper {
  align-items: center;
}

.ant-modal-title {
  font-family: "SourceSansPro-Bold";
  font-size: 2rem;
}

.ant-modal-content {
  padding: 0rem 3rem;
}

.ant-modal-header {
  border-bottom: 0.1rem solid #e8e8e8;
}

.ant-modal-body {
  padding: 2rem;
}

.ant-switch-checked {
  background-color: rgba(84, 0, 168, 0.6);
}

.ant-switch-small {
  min-width: 2.8rem;
  height: 1rem;
  line-height: 1.4rem;
}

.ant-switch {
  background-color: #eee7f5;
}

.ant-switch-checked {
  background-color: rgba(84, 0, 168, 0.6);
}

.ant-switch-small.ant-switch:after {
  left: -0.1rem;
}

.ant-switch:after {
  width: 1.5rem;
  height: 1.5rem;
  top: -0.3rem;
  background-color: #5400a8;
}

.ant-switch-small.ant-switch.ant-switch-checked:after {
  left: 100%;
}

.ant-switch-checked:after {
  margin-left: 0.1rem;
}

.ant-slider-track {
  height: 0.3rem;
  background-color: #5400a8;
}

.ant-slider-handle.ant-slider-handle-1,
.ant-slider-handle.ant-slider-handle-2 {
  width: 1.7rem;
  height: 1.7rem;
  margin-top: -0.7rem;
  border: 0.1rem solid #5400a8;
}

.ant-slider-handle:focus {
  box-shadow: 0 0 0 0.3rem rgba(84, 0, 168, 0.8);
}

.ant-slider-track {
  height: 0.3rem;
  background-color: #5400a8;
}

.ant-slider:hover .ant-slider-track {
  background-color: #5400a8;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #5400a8;
  box-shadow: 0 0 0 0.3rem rgba(84, 0, 168, 0.4);
}

.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
  border-color: #5400a8;
  box-shadow: 0 0 0 0.3rem rgba(84, 0, 168, 0.4);
}

.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 1.2rem;
}

.ant-select-multiple .ant-select-selector {
  border: none;
}

.ant-drawer-content {
  overflow: visible;
}

.ant-drawer-body {
  padding: 0rem;
}

.ant-select,
.ant-select-single,
.ant-select-show-arrow,
.ant-select-multiple,
.ant-select-show-search,
.ant-tabs {
  width: 100%;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #5400a8;
}

.ant-tabs-ink-bar {
  background-color: #5400a8;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: rgba(84, 0, 168, 0.6);
}

span.anticon.anticon-question-circle {
  margin-left: 0.5rem;
}

.ant-modal {
  top: 0;
}

/* .ant-tooltip-arrow::before {
  color: rgb(84, 0, 168, 1);
} */
/* .ant-tooltip-inner {
  background-color: black;
  color: white;
} */
/* .ant-select
  .ant-select-multiple
  .ant-select-show-search
  > .ant-select-selection-item {
  font-family: "SourceSansPro-Semibold";
  background: #515151;
  color: white;
  border-radius: 1.5rem;
}
.ant-select
  .ant-select-multiple
  .ant-select-show-search
  > .ant-select-selection-item-remove {
  line-height: 1.7rem;
  color: white;
} */

@media only screen and (max-width: 425px) {
  .ant-modal-content {
    padding: 0rem;
  }
}

.ant-tooltip-inner {
  font-size: 1.2rem;
}
